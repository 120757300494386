<template>
	<div
		v-if="model.readlines && model.readlines.length"
		ref="rootEl"
		class="readline__inpage"
	>
		<div class="readline__wrapper">
			<div
				class="readline"
				:class="{
					'readline--fixed': isReadlineFixed,
					'readline--overflow': isScrollable,
					'readline--overflow-left': showOverflowLeft,
					'readline--overflow-right': showOverflowRight,
				}"
			>
				<nav
					ref="navEl"
					class="readline__nav"
					@scroll="onNavScroll"
				>
					<a
						v-for="(item, i) in model.readlines"
						:key="i"
						ref="navLinkEls"
						class="readline__item"
						:class="{
							'readline__item--selected': readlineState[i],
							'readline__item--current': i === readlineCurrent,
						}"
						:href="`#${item.readlineId}`"
						@click.prevent="scrollToTarget(item.readlineId, item.readlineTitle)"
						>{{ item.readlineTitle }}</a
					>
				</nav>
				<div class="readline__progress">
					<div
						class="readline__progress__inner"
						:style="{ width: `${scrollProgress}%` }"
					></div>
				</div>
			</div>
		</div>
	</div>
</template>

<script setup lang="ts">
	import { computed, onBeforeUnmount, onMounted, ref } from 'vue';
	import { throttle } from 'lodash';
	import { IReadlineBlock } from '@/types/block';
	import { useAppStateStore } from '@/stores/appState';
	import { dataLayer, gtmNavigationalTracking } from '@/components/utilities/trackingUtility';
	// import { useBreakpointsStore } from '@/stores/breakpoints'

	// Stores
	const appStateStore = useAppStateStore();
	// const breakpointsStore = useBreakpointsStore()

	// Props
	const props = defineProps<{
		model: IReadlineBlock;
	}>();

	// Element refs
	const rootEl = ref();
	const navEl = ref();
	const navLinkEls = ref<HTMLElement[]>([]);

	// Data
	const pageScrollOffset = 200;
	const isScrollable = ref(false);
	const showOverflowLeft = ref(false);
	const showOverflowRight = ref(false);

	const navResizeObserver = new ResizeObserver((entries) => {
		entries.forEach((entry) => {
			const {
				target: { clientWidth, scrollWidth },
			} = entry;
			isScrollable.value = clientWidth < scrollWidth;
			// entry.target.classList.toggle('scrollable', entry.target.clientWidth < entry.target.scrollWidth);
		});
	});

	const isReadlineFixed = computed(() => {
		if (!rootEl.value) {
			return false;
		}
		const readlineOffsetTop = rootEl.value.offsetTop;
		return appStateStore.scrollPosition > readlineOffsetTop - 90; // When readline applies position: fixed
	});

	const scrollProgress = computed(() => {
		return Math.round(
			(appStateStore.scrollPosition / (document.body.scrollHeight - document.body.offsetHeight)) * 100
		);
	});

	const readlineState = ref<boolean[]>([]);

	const readlineCurrent = ref(0);

	const scrollToTarget = (elId: string, elTitle: string) => {
		const target = document.getElementById(elId);
		const targetOffset = target?.offsetTop;
		if (targetOffset) {
			scroll({
				top: targetOffset - pageScrollOffset,
				behavior: 'smooth',
			});
		}

		// Track when clicking on readline item
		if (dataLayer) {
			dataLayer.push(gtmNavigationalTracking(elTitle, 'readline navigation menu', history.state.current));
		}
	};

	const onPageScroll = throttle(() => {
		readlineState.value = props.model.readlines.map((item) => {
			const el = document.getElementById(item.readlineId);
			if (!el) {
				return false;
			}
			const elOffsetTop = el.offsetTop;
			return elOffsetTop < appStateStore.scrollPosition + pageScrollOffset + 1;
		});
		readlineCurrent.value = readlineState.value.filter((el) => el).length - 1;
		if (navEl.value) {
			const scrollTarget = navLinkEls.value[readlineCurrent.value]?.offsetLeft ?? 0;
			navEl.value.scroll({
				left: scrollTarget,
				behavior: 'smooth',
			});
		}
	}, 50);

	const onNavScroll = throttle(() => {
		showOverflowLeft.value = navEl.value.scrollLeft > 0;
		showOverflowRight.value = navEl.value.scrollLeft < navEl.value.scrollWidth - navEl.value.clientWidth;
	}, 300);

	onMounted(() => {
		window.addEventListener('scroll', onPageScroll);
		onPageScroll();
		navResizeObserver.observe(navEl.value);
	});

	onBeforeUnmount(() => {
		window.removeEventListener('scroll', onPageScroll);
	});
</script>

<style lang="scss">
	@import './ReadlineBlock.module.scss';
</style>
