<template>
	<smart-link
		v-if="privatePageUrl"
		class="segments__link"
		:class="{ 'segments__link--active': isPrivatePageActive }"
		:url="privatePageUrl"
		:trackingData="gtmNavigationalTracking(privateLabel, 'top navigation', privatePageUrl)"
	>
		{{ privateLabel ?? 'Privat' }}
	</smart-link>
	<span
		v-if="businessPageUrl"
		class="segments__link--divider"
	></span>
	<smart-link
		v-if="businessPageUrl"
		class="segments__link"
		:class="{ 'segments__link--active': isBusinessPageActive }"
		:url="businessPageUrl"
		:trackingData="gtmNavigationalTracking(businessLabel, 'top navigation', businessPageUrl)"
	>
		{{ businessLabel ?? 'Erhverv' }}
	</smart-link>
	<span
		v-if="agriculturePageUrl"
		class="segments__link--divider"
	></span>
	<smart-link
		v-if="agriculturePageUrl"
		class="segments__link"
		:class="{ 'segments__link--active': isAgriculturePageActive }"
		:url="agriculturePageUrl"
		:trackingData="gtmNavigationalTracking(agricultureLabel || 'Landbrug', 'top navigation', agriculturePageUrl)"
	>
		{{ agricultureLabel }}
	</smart-link>
</template>

<script setup lang="ts">
	import { computed } from 'vue';
	import { useRoute } from 'vue-router';
	import { gtmNavigationalTracking } from '@/components/utilities/trackingUtility';

	const route = useRoute();

	const props = defineProps<{
		privateLabel: string;
		privatePageUrl?: string;
		businessLabel: string;
		businessPageUrl?: string;
		agricultureLabel?: string;
		agriculturePageUrl?: string;
	}>();

	const isBusinessPageActive = computed(() => route.path.startsWith(props.businessPageUrl || '/erhverv/'));
	const isAgriculturePageActive = computed(() => route.path.startsWith(props.agriculturePageUrl || '/landbrug/'));
	const isPrivatePageActive = computed(() => {
		return !isBusinessPageActive.value && !isAgriculturePageActive.value;
	});
</script>

<style lang="scss">
	@import './TopNavigation.module.scss';
</style>
