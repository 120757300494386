<!--
    Will select a view component based on the content type name.
    It gets its `model` from the vuex stores `cmsDataModel` module that's
    automatically updated in OPE when data is edited (through the `contentSaved` event).
    The store also includes `modelLoaded` that's set to true when the model has
    been populated in the store. This flag toggles the rendering of the page with `v-if`.
-->

<template>
	<template v-if="modelLoaded">
		<component
			:is="getComponentForModel(model)"
			:model="model"
		/>
	</template>
</template>

<script setup lang="ts">
	import { computed, resolveComponent } from 'vue';
	import { useCmsDataStore } from '@/stores/cmsData';
	import { defaultCmsConfig } from '@/config';
	import { ContentData } from '@/episerver/models';

	const cmsDataStore = useCmsDataStore();

	const model = computed(() => {
		return cmsDataStore.model;
	});

	const modelLoaded = computed(() => {
		return cmsDataStore.modelLoaded;
	});

	const getComponentForModel = (contentModel: ContentData | Record<string, never>): string | null => {
		if (!contentModel.contentType) return null;

		// Blocks are only loaded in edit mode, i.e.
		// we should display them in our preview component.
		if (contentModel.contentType === 'Block') {
			return defaultCmsConfig.componentSelector.blockPreviewComponent;
		}
		// Pick the most specific view component, i.e. first view that matches
		// the the content type name in the content type inheritance chain.
		const resolved = resolveComponent(contentModel.contentType);

		if (typeof resolved === 'object') return contentModel.contentType;

		return null;
	};
</script>
