export const dataLayer = (window as any).dataLayer || [];

export const gtmNavigationalTracking = (action: string, type: string, destination: string) => {
	const domainUrl = window.location.origin;
	return {
		event: 'trackNavigation',
		navigationAction: action,
		navigationType: type,
		navigationDestination: domainUrl + destination,
	};
};

export const gtmTrackViewPromotion = (creativeName: string, creativeSlot: string, promotionName: string) => {
	dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.
	dataLayer.push({
		event: 'view_promotion',
		ecommerce: {
			items: [
				{
					creative_name: creativeName,
					creative_slot: creativeSlot,
					promotion_name: promotionName,
				},
			],
		},
	});
};

export const gtmTrackSelectPromotion = (creativeName: string, creativeSlot: string, promotionName: string) => {
	return {
		event: 'select_promotion',
		ecommerce: {
			items: [
				{
					creative_name: creativeName,
					creative_slot: creativeSlot,
					promotion_name: promotionName,
				},
			],
		},
	};
};

export const handleGtmLabel = (richText: string, alternativeGtmLabel: string) => {
	const parser = new DOMParser();
	const parsedDocument = parser.parseFromString(richText, 'text/html');
	const gtmRichTextLabel = parsedDocument.body.textContent;

	return gtmRichTextLabel ? gtmRichTextLabel : alternativeGtmLabel;
};

export const handleGtmHeader = (richText: string, alternativeGtmHeader: string) => {
	const parser = new DOMParser();
	const parsedDocument = parser.parseFromString(richText, 'text/html');
	const h2Element = parsedDocument.querySelector('h2');
	const gtmRichTextHeader = h2Element ? h2Element.textContent : null;

	return gtmRichTextHeader ? gtmRichTextHeader : alternativeGtmHeader;
};

// Todo KLK remove category methods when implemented in justice league

export const getTrackCategory2 = (name: string) => {
	switch (name) {
		case 'bilforsikring':
			return 'transport';
		case 'husforsikring': // fallthrough
		case 'fritidshusforsikring': // fallthrough
		case 'indboforsikring':
			return 'housing';
		case 'rejseforsikring':
			return 'travel';
		case 'ulykkesforsikring':
			return 'health';
		case 'børneulykkesforsikring':
			return 'health';
		case 'hundeforsikring':
			return 'health';
		case 'børnefamilieforsikring':
			return 'health, housing';
		default:
			return name;
	}
};

export const getTrackCategory3 = (name: string) => {
	switch (name) {
		case 'bilforsikring':
			return 'car';
		case 'husforsikring':
			return 'house';
		case 'fritidshusforsikring':
			return 'holidayhouse';
		case 'indboforsikring':
			return 'home';
		case 'rejseforsikring':
			return 'travel';
		case 'ulykkesforsikring':
			return 'accident';
		case 'børneulykkesforsikring':
			return 'childaccident';
		case 'hundeforsikring':
			return 'dog';
		case 'børnefamilieforsikring':
			return 'health, house';
		default:
			return name;
	}
};
export type TrackProductItem = {
	item_id: string;
	item_name: string;
	affiliation: string;
	coupon: string;
	discount: number;
	index?: number;
	item_brand: string;
	item_category: string;
	item_category2?: string;
	item_category3?: string;
	item_variant: string;
	item_list_id?: string;
	item_list_name?: string;
	price: number;
	quantity: number;
};
