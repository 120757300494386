<template>
	<component
		:is="tagType"
		v-if="url"
		ref="linkEl"
		:to="url"
		:href="url"
		:class="defaultLinkCssClass"
		:target="target"
		:title="title"
		:data-gtm-label="defaultGtmLabel"
		:data-gtm-action="defaultGtmAction"
		:rel="isExternalLink ? 'noopener' : null"
		@click="handleClick"
	>
		<slot />
	</component>
</template>

<script setup lang="ts">
	import { ref, onMounted, computed, onUpdated } from 'vue';
	import { useCmsContextStore } from '@/stores/cmsContext';
	import { defaultCmsConfig } from '@/config';
	import { dataLayer } from '@/components/utilities/trackingUtility';

	/* eslint-disable vue/require-default-prop, no-undef */
	type SmartLinkProps = {
		url: string;
		defaultLinkCssClass?: any;
		target?: string;
		title?: string;
		gtmLabel?: string;
		gtmAction?: string;
		isExternalLink?: boolean;
		useVueRoute?: boolean;
		trackingData?: Object;
		promotionalTracking?: Object;
	};

	const cmsContextStore = useCmsContextStore();

	const props = withDefaults(defineProps<SmartLinkProps>(), {
		defaultLinkCssClass: () => defaultCmsConfig.link.cssClass,
		isExternalLink: false,
		useVueRoute: true,
		target: '_self',
	});

	const linkEl = ref<HTMLElement>();
	const linkText = ref('');

	const tagType = computed(() => {
		// summary:
		//      Define whether we should use the tag 'a' or 'router-link' when generating a link.
		//      The reason is because <router-link> doesn't support absolute link
		//      (https://github.com/vuejs/vue-router/issues/1131), which happens when we link to a page
		//      in another site in a multi-sites system.
		//      There is an open feature-request for making 'router-link' support absolute links.
		//      https://github.com/vuejs/vue-router/issues/1280
		//
		//      Always user an 'a' tag in edit mode to update the Episerver UI
		if (cmsContextStore.inEditMode) {
			return 'a';
		}
		return props.useVueRoute ? 'router-link' : 'a';
	});

	const defaultGtmLabel = computed(() => {
		// use linktext for gtm tracking if none provided
		return props.gtmLabel ? props.gtmLabel : linkText.value;
	});

	const defaultGtmAction = computed(() => {
		return props.gtmAction ? props.gtmAction : 'button';
	});

	const getLinkText = () => {
		// @ts-expect-error
		const element = linkEl.value?.$el ? linkEl.value?.$el : linkEl.value;
		linkText.value = (element as HTMLElement)?.innerText ?? '';
	};

	const handleClick = (event: any) => {
		event.target.blur();

		if (!dataLayer) {
			return;
		}

		// dataLayer object which will be defined in individual components
		if (props.trackingData) {
			dataLayer.push(props.trackingData);
		}

		// dataLayer object which will be defined in individual components
		if (props.promotionalTracking && props.promotionalTracking !== null) {
			dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.
			dataLayer.push(props.promotionalTracking);
		}
	};

	onMounted(() => {
		getLinkText();
	});

	onUpdated(() => {
		getLinkText();
	});
</script>
