<template>
	<div class="pagination">
		<div
			v-if="current > 0"
			class="pagination__item"
		>
			<smart-link
				class="pagination__link"
				:url="toPage(current - 1)"
			>
				&lt;
			</smart-link>
		</div>
		<div
			v-for="(page, index) in pages"
			:key="index"
			class="pagination__item"
		>
			<smart-link
				class="pagination__link"
				:class="{ 'pagination__link--current': index === current }"
				:url="toPage(index)"
			>
				{{ page }}
			</smart-link>
		</div>
		<div
			v-if="current < pages - 1"
			class="pagination__item"
		>
			<smart-link
				class="pagination__link"
				:url="toPage(current + 1)"
			>
				>
			</smart-link>
		</div>
	</div>
</template>

<script setup lang="ts">
	import { computed } from 'vue';
	import { useRouter, useRoute } from 'vue-router';

	const router = useRouter();
	const route = useRoute();

	const props = defineProps<{
		total: number;
		size: number;
	}>();

	const pages = computed(() => {
		if (!props.total || !props.size) {
			return 0;
		}
		return Math.ceil(props.total / props.size);
	});

	const current = computed(() => {
		if (!route.query.p) {
			return 0;
		}
		return +route.query.p;
	});

	// TODO: Later implementation?
	// const goToPage = (page: number): void => {
	// 	router.push({ path: route.path, query: { ...route.query, p: page } });
	// };
	const toPage = (page: number) => {
		return router.resolve({ path: route.path, query: { ...route.query, p: page } }).fullPath;
	};
</script>

<style lang="scss">
	@import './Pagination.module.scss';
</style>
