import { defineStore } from 'pinia';

export const SELECTABLE_CONTAINER_STORE_ID = 'Selectable_store';

type SelectableContainerStoreType = {
	selectableContainers: Map<string, Array<string>>;
	tracked: boolean;
};

export const SelectableContainerStore = defineStore(SELECTABLE_CONTAINER_STORE_ID, {
	state: (): SelectableContainerStoreType => ({
		selectableContainers: new Map<string, Array<string>>(),
		tracked: false,
	}),
	actions: {
		add(container: string, productId: string) {
			const products = this.selectableContainers.get(container);
			if (!products) {
				const p = [productId];
				this.selectableContainers.set(container, p);
			} else {
				products?.push(productId);
			}
		},
		remove(container: string, productId: string) {
			const products = this.selectableContainers.get(container);
			if (!products) {
				// do nothing
			} else {
				let once = false;
				const prods: Array<string> = [];
				products.forEach((pId) => {
					if (!once && pId === productId) {
						once = true;
					} else {
						prods.push(pId);
					}
				});

				this.selectableContainers.set(container, prods);
			}
		},
	},
});
