import { createApp } from 'vue';
import { createPinia } from 'pinia';
import { toPairs } from 'lodash-es';
import App from './App.vue';
// import EpiEdit from '@/directives/epiEdit'
import inView from '@/directives/inView';
import router from './router';
import './epiBootstrap';
import { defaultCmsConfig } from './config';
import { LazyImage, SmartSvg, SmartLink, SmartImage, Countdown } from '@/components/shared/index';
defaultCmsConfig.contentArea.rootHtmlTag = 'section';

import '/src/assets/scss/framework.scss';

const pinia = createPinia();

const app = createApp(App)
	// .directive('epi-edit', EpiEdit)
	.use(pinia)
	.use(router)
	.use(inView)
	.component('smart-image', SmartImage)
	.component('smart-link', SmartLink)
	.component('smart-svg', SmartSvg)
	.component('lazy-image', LazyImage)
	.component('countdown', Countdown);

const blocks = import.meta.glob('./components/blocks/*/*.vue', { eager: true });
const pages = import.meta.glob('./components/pages/*.vue', { eager: true });

const registerComponents = (components: Object) => {
	toPairs(components).forEach(([path, moduleDefinition]) => {
		// Get name of component, based on filename
		const componentName = path
			.split('/')
			.pop()!
			.replace(/\.\w+$/, '');

		// Register component on this Vue instance
		// Look for the component options on `.default`, which will
		// exist if the component was exported with `export default`,
		// otherwise fall back to module's root.
		app.component(componentName, moduleDefinition.default || moduleDefinition);
	});
};

[blocks, pages].forEach((comps) => {
	registerComponents(comps);
});

app.mount('#app');

declare global {
	// eslint-disable-next-line no-unused-vars
	interface Window {
		epi: {
			inEditMode: boolean;
			isEditable: boolean;
			// eslint-disable-next-line no-unused-vars
			subscribe(eventName: string, callback: (message: { url: string }) => void): void;
			ready: boolean;
		};
	}
}
