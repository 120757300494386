<template>
	<Layout></Layout>
</template>

<script setup lang="ts">
	import { onMounted, onBeforeUnmount, watch, ref } from 'vue';
	import { useRoute } from 'vue-router';
	import { defaultConfig } from '@/episerver/config';
	import { useBreakpointsStore } from './stores/breakpoints';
	import { useAppStateStore } from './stores/appState';
	import { Layout } from '@/components/layout/index';
	import { dataLayer } from './components/utilities/trackingUtility';
	import router from '@/router';

	const timestampTracking = ref(0);

	const route = useRoute();

	const appStateStore = useAppStateStore();
	const breakpointsStore = useBreakpointsStore();

	breakpointsStore.breakpoints.forEach(({ name, size }) => {
		const mediaQueryList = window.matchMedia(`(max-width: ${size - 1}px)`);
		breakpointsStore.updateBreakpoint({ name, matches: mediaQueryList.matches });
		mediaQueryList.addEventListener('change', (e) => {
			breakpointsStore.updateBreakpoint({ name, matches: e.matches });
		});
	});

	const appendEpiEditScripts = (domainUrl: string) => {
		const domainScript = document.createElement('script');
		domainScript.innerHTML = "document.domain = 'localhost';";
		document.body.appendChild(domainScript);

		const communicationScript = document.createElement('script');
		communicationScript.src = `${domainUrl}/episerver/cms/latest/clientresources/epi-cms/communicationinjector.js`;
		document.body.appendChild(communicationScript);
	};

	const handleStickyNav = () => {
		appStateStore.setScrollPosition(document.documentElement.scrollTop || document.body.scrollTop);
	};

	// Page view tracking
	const pageViewTracking = () => {
		if (dataLayer) {
			dataLayer.push({
				event: 'trackPageview',
			});
		}
	};

	// Genereal data layer
	const generalDataLayer = () => {
		if (dataLayer) {
			const pageSection = route.path.startsWith('/erhverv/') ? 'erhverv' : 'privat';
			dataLayer.push({
				pageSection: pageSection,
				brand: 'alm. brand',
				previousPageUrl: history.state.back,
				platform: 'web',
				fullUrl: window.location.href,
			});
		}
	};

	// Timestamp tracking
	const handleTimestampTracking = (timestamp: number) => {
		if (!dataLayer) return;
		timestampTracking.value = timestamp;
		dataLayer.push({
			event: 'trackTimestamp',
			timestampTracking: timestampTracking.value,
		});
	};

	// Run tracking on timestamp every 30 seconds
	const trackTimeInterval = () => {
		if (!dataLayer) return;
		// Fire every 30 seconds
		const intervalId = setInterval(() => {
			timestampTracking.value += 30;
			handleTimestampTracking(timestampTracking.value);
		}, 30000);

		// Stop after 180 seconds
		setTimeout(() => {
			clearInterval(intervalId);
		}, 180000);
	};

	onMounted(() => {
		const element = document.getElementById('app');
		const domainUrl =
			element?.getAttribute('data-domain') ??
			// ?? import.meta.env.VITE_APP_BASEURL
			'https://localhost:40200';
		const contentDeliveryApiUri =
			// element?.getAttribute('data-contentdelivery-url') ??
			// ?? import.meta.env.VITE_APP_CONTENTDELIVERY_URL
			'/api/ab/v3';

		defaultConfig.apiUrl = domainUrl + contentDeliveryApiUri;
		defaultConfig.selectAllProperties = true;
		defaultConfig.expandAllProperties = true;

		/*
		 * If the `epieditmode` parameter is present we know we're
		 * in either edit- or preview mode and should include following scripts.
		 */
		if (route.query.epieditmode || document.location.search.includes('epieditmode')) {
			appendEpiEditScripts(domainUrl);
		}

		window.addEventListener('scroll', handleStickyNav);

		handleStickyNav();
		trackTimeInterval();
	});

	onBeforeUnmount(() => {
		window.removeEventListener('scroll', handleStickyNav);
	});

	router.afterEach(() => {
		window.scrollTo(0, 0);
		setTimeout(() => {
			generalDataLayer();
			pageViewTracking();
		}, 1250);
	});

	watch(route, () => {
		window.performance.mark('end');
	});
</script>
