import { defineStore } from 'pinia';

type SearchQuery = {
	q: string;
	p: number;
};

type SearchSuggestionsItem = {
	text: string;
	html: string;
};

type SearchSuggestions = SearchSuggestionsItem[];

type SearchResultsItem = {
	title: string;
	text: string;
	url: string;
};

// TODO: search functionality?
// type SearchResults = {
// 	total: number;
// 	pages: number;
// 	items: SearchResultsItem[];
// };

export type SearchState = {
	query: string;
	currentPage: number;
	results: SearchResultsItem[];
	resultsTotal: number;
	resultsPerPage: number;
	totalPages: number;
	isActive: boolean;
	hasAutoSuggest: boolean;
	suggestions: SearchSuggestions;
};

// TODO: search functionality?
// const state: SearchState = {
// 	query: '',
// 	currentPage: 0,
// 	results: [],
// 	resultsTotal: 0,
// 	resultsPerPage: 4,
// 	totalPages: 0,
// 	isActive: false,
// 	hasAutoSuggest: false,
// 	suggestions: [],
// };

export const useSearchStore = defineStore('search', {
	state: (): SearchState => ({
		query: '',
		currentPage: 0,
		results: [],
		resultsTotal: 0,
		resultsPerPage: 4,
		totalPages: 0,
		isActive: false,
		hasAutoSuggest: false,
		suggestions: [],
	}),
	actions: {
		setIsActive(payload: boolean) {
			this.isActive = payload;
		},
		getSearchResults(payload: SearchQuery): void {
			console.log('getSearchResults', payload);
			const total = 12;
			const pages = 3;
			let items: SearchResultsItem[] = [];
			switch (payload.p) {
				case 0:
					items = [
						{
							title: 'Cyberforsikring',
							text: 'Forsikring skal være nemt og skabe sikre rammer om dit liv. Derfor skal forsikringer først og fremmest passe til dig og dem, du har nær. Vi arbejder hele tiden på at kunne give dig de bedste tilpassede løsninger.',
							url: '/forsikringer',
						},
						{
							title: 'Fragtføreransvarsforsikring',
							text: 'En Campingvognsforsikring dækker skader på din campingvogn, dens tilbehør og indbo. Kør trygt afsted og nyd friheden - din campingvogn er godt dækket.',
							url: '/forsikringer',
						},
						{
							title: 'Knallertforsikring',
							text: 'En Knallertforsikring hos os opfylder altid de lovpligtige minimumskrav med vores Ansvarsforsikring. Har du en knallert 45, har du mulighed for at udvide forsikringen og få en endnu bedre dækning.',
							url: '/forsikringer',
						},
						{
							title: 'Sælgeransvars­forsikring',
							text: 'En Sælgeransvarsforsikring dækker dig som sælger mod de fejl og mangler, køber kan finde, og som ikke er skrevet i boligens tilstandsrapport. Så skal du ikke bekymre dig om boligen, efter den er solgt.',
							url: '/forsikringer',
						},
					];
					break;
				case 1:
					items = [
						{
							title: 'Cyberforsikring',
							text: 'Forsikring skal være nemt og skabe sikre rammer om dit liv. Derfor skal forsikringer først og fremmest passe til dig og dem, du har nær. Vi arbejder hele tiden på at kunne give dig de bedste tilpassede løsninger.',
							url: '/forsikringer',
						},
						{
							title: 'Fragtføreransvarsforsikring',
							text: 'En Campingvognsforsikring dækker skader på din campingvogn, dens tilbehør og indbo. Kør trygt afsted og nyd friheden - din campingvogn er godt dækket.',
							url: '/forsikringer',
						},
						{
							title: 'Knallertforsikring',
							text: 'En Knallertforsikring hos os opfylder altid de lovpligtige minimumskrav med vores Ansvarsforsikring. Har du en knallert 45, har du mulighed for at udvide forsikringen og få en endnu bedre dækning.',
							url: '/forsikringer',
						},
						{
							title: 'Sælgeransvars­forsikring',
							text: 'En Sælgeransvarsforsikring dækker dig som sælger mod de fejl og mangler, køber kan finde, og som ikke er skrevet i boligens tilstandsrapport. Så skal du ikke bekymre dig om boligen, efter den er solgt.',
							url: '/forsikringer',
						},
					];
					break;
				case 2:
					items = [
						{
							title: 'Cyberforsikring',
							text: 'Forsikring skal være nemt og skabe sikre rammer om dit liv. Derfor skal forsikringer først og fremmest passe til dig og dem, du har nær. Vi arbejder hele tiden på at kunne give dig de bedste tilpassede løsninger.',
							url: '/forsikringer',
						},
						{
							title: 'Fragtføreransvarsforsikring',
							text: 'En Campingvognsforsikring dækker skader på din campingvogn, dens tilbehør og indbo. Kør trygt afsted og nyd friheden - din campingvogn er godt dækket.',
							url: '/forsikringer',
						},
						{
							title: 'Knallertforsikring',
							text: 'En Knallertforsikring hos os opfylder altid de lovpligtige minimumskrav med vores Ansvarsforsikring. Har du en knallert 45, har du mulighed for at udvide forsikringen og få en endnu bedre dækning.',
							url: '/forsikringer',
						},
						{
							title: 'Sælgeransvars­forsikring',
							text: 'En Sælgeransvarsforsikring dækker dig som sælger mod de fejl og mangler, køber kan finde, og som ikke er skrevet i boligens tilstandsrapport. Så skal du ikke bekymre dig om boligen, efter den er solgt.',
							url: '/forsikringer',
						},
					];
					break;
				default:
					break;
			}
			this.$patch({
				query: payload.q,
				currentPage: payload.p,
				results: items,
				resultsTotal: total,
				totalPages: pages,
			});
		},
		getSearchSuggestions(payload: string) {
			const suggestions = [
				'indboforsikring',
				'forsikring',
				'forsikringsbetingelser',
				'forsikringspolice',
				'forsikringsoversigt',
				'forsikringskort',
				'rejseforsikring',
				'elektronikforsikring',
				'ulykkesforsikring',
				'opsig forsikring',
			];
			const searchSuggestions = suggestions
				.filter((searchResult: string) => searchResult.startsWith(payload))
				.map((searchResult: string) => {
					const index = searchResult.indexOf(payload);
					const text = searchResult;
					const html = `${searchResult.substring(0, index)}${searchResult.substring(
						index,
						index + text.length
					)}<strong>${searchResult.substring(index + text.length)}</strong>`;
					return {
						text,
						html,
					};
				});
			this.suggestions = searchSuggestions;
		},
	},
});
