<template>
	<layout-meta />
	<layout-notification
		v-if="config.notificationReference"
		:model="config.notificationReference"
		:headerModel="config.headerReference"
	/>
	<layout-navigation
		v-if="config.headerReference"
		:model="config.headerReference"
	/>
	<main class="main">
		<router-view></router-view>
	</main>
	<layout-footer
		v-if="config.footerReference"
		:model="config.footerReference"
	/>
	<layout-albotta v-if="!hideAlbotta" />
</template>

<script setup lang="ts">
	import { computed, ref, watch } from 'vue';
	import { useCmsDataStore } from '@/stores/cmsData';
	import {
		LayoutMeta,
		LayoutNavigation,
		LayoutFooter,
		LayoutNotification,
		LayoutAlbotta,
	} from '@/components/layout/index';

	const cmsDataStore = useCmsDataStore();

	let hideChatWindow = false;

	const hideAlbotta = ref(true);

	const config = computed(() => {
		// console.log('Layout config', cmsDataStore.configuration) // proxy
		return cmsDataStore.configuration;
	});

	watch(
		() => cmsDataStore.model.hideAlbottaScript,
		(newHideAlbotta) => {
			hideChatWindow = newHideAlbotta;
			hideAlbotta.value = newHideAlbotta;
			const chatWindow = document.querySelector('#chat-container') as HTMLElement;

			if (chatWindow) {
				chatWindow.style.display = hideChatWindow ? 'none' : '';
			}
		}
	);
</script>
