import { defineStore } from 'pinia';

type BreakpointName = 'xs' | 'sm' | 'md' | 'lg' | 'xl';

type Breakpoint = {
	name: BreakpointName;
	size: number;
	matches: boolean;
};

type BreakpointsStoreState = {
	breakpoints: Breakpoint[];
};

type UpdateBreakpointPayload = {
	name: BreakpointName;
	matches: boolean;
};

export const useBreakpointsStore = defineStore('brakpoints', {
	state: (): BreakpointsStoreState => ({
		breakpoints: [
			{ name: 'xs', size: 576, matches: false },
			{ name: 'sm', size: 768, matches: false },
			{ name: 'md', size: 1024, matches: false },
			{ name: 'lg', size: 1320, matches: false },
			{ name: 'xl', size: 1600, matches: false },
		],
	}),
	getters: {
		matches() {
			return (name: BreakpointName) => !!this.breakpoints.find((bp) => bp.name === name)?.matches;
		},
	},
	actions: {
		updateBreakpoint(payload: UpdateBreakpointPayload) {
			const bp = this.breakpoints.find((breakpoint) => breakpoint.name === payload.name);
			if (bp) {
				bp.matches = payload.matches;
			}
		},
	},
});
