import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import PageComponentSelector from '../components/pages/PageComponentSelector.vue';
import { PageData } from '../episerver/models';
import { useCmsDataStore } from '@/stores/cmsData';
import { useAppStateStore } from '@/stores/appState';

const routes: Array<RouteRecordRaw> = [
	{
		name: 'page-component-selector',
		path: '/:pathMatch(.*)',
		component: PageComponentSelector,
	},
];

const router = createRouter({
	history: createWebHistory(),
	routes,
	scrollBehavior(to, from, savedPosition) {
		// scroll to anchor if available in the url
		if (to.hash) {
			return new Promise((resolve) => {
				setTimeout(() => {
					resolve({ el: to.hash, behavior: 'smooth', top: 200 });
				}, 150);
			});
		}
		// return to scrolled position using browser history back
		if (savedPosition) {
			return new Promise((resolve) => {
				setTimeout(() => {
					resolve(savedPosition);
				}, 150);
			});
		}
	},
});

router.beforeEach((to, from, next) => {
	const cmsDataStore = useCmsDataStore();
	// URL is updated by vue-route-sync, and when time travelling with the
	// debugger we don't want to trigger a model commit as the model is already
	// part of the store holding the url update.
	if (to.name === 'page-component-selector' && (cmsDataStore.model as PageData).url !== to.fullPath) {
		cmsDataStore.updateModelByUrl(to.fullPath).then(() => {
			switch (cmsDataStore.status) {
				case 'NOTFOUND':
				case 'UNAUTHORIZED':
				case 'ACCESSDENIED':
				default:
			}
		});
	}

	next();
});

router.afterEach(() => {
	const appStateStore = useAppStateStore();
	if (appStateStore.isMenuOpen) {
		appStateStore.toggleMenu(false);
	}
});

export default router;
