<template>
	<smart-link
		class="tile-block"
		:url="linkUrl()"
		:target="model.target"
		:useVueRoute="model.url?.useVueRoute"
		:trackingData="gtmNavigationalTracking(model.title, 'tile', linkUrl())"
	>
		<tile-base
			:title="model.title"
			:icon="model.icon"
			:backgroundColor="model.backgroundColor?.cssClass"
		/>
	</smart-link>
</template>

<script setup lang="ts">
	import { ITileBlock } from '@/types/block';
	import { SmartLink } from '@/components/shared/index';
	import { TileBase } from './TileBase';
	import { gtmNavigationalTracking } from '@/components/utilities/trackingUtility';

	const props = defineProps<{
		model: ITileBlock;
		storybook?: boolean;
	}>();

	const linkUrl = () => {
		return props.model?.url && props.model.url.url ? props.model.url.url : '#';
	};
</script>

<style lang="scss" scoped>
	@import './TileBlock.module.scss';
</style>
