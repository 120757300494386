import { dataLayer } from './trackingUtility';

// Setting up connection and getting conversation id
let _conversationId = sessionStorage.getItem('conversationId');

const chatPanel = (window as any).boostChatPanel({
	apiUrlBase: 'https://almbrandekstern.boost.ai/api',
	conversationId: _conversationId,
});

let autoPoppedUp = false;
let autoPopUpChatDelay: any;

// Listen for the conversationIdChanged event
chatPanel.addEventListener('conversationIdChanged', function (event: any) {
	const conversationId = event.detail.conversationId;
	_conversationId = conversationId;
	sessionStorage.setItem('conversationId', conversationId);
});

// Handle opening Albotta
export function openChatBot() {
	chatPanel.show();

	sessionStorage.setItem('ChatBotOpen', 'true');
	if (!autoPoppedUp) clearTimeout(autoPopUpChatDelay);
	sessionStorage.getItem('conversationId');

	dataLayer.push({
		event: 'trackChat',
		chatAction: autoPoppedUp ? 'chat auto-popup' : 'chat start',
	});
}

// Does not pop up
if (sessionStorage.getItem('ChatBotOpen') === 'true') {
	openChatBot();
}

// Listen for messageSent
chatPanel.addEventListener('messageSent', function () {
	dataLayer.push({
		event: 'trackChat',
		chatAction: 'chat message send',
	});
});

// Listen for conversationDownloaded
chatPanel.addEventListener('conversationDownloaded', function () {
	dataLayer.push({
		event: 'trackChat',
		chatAction: 'chat download conversation',
	});
});

// Listen for conversationDeleted
chatPanel.addEventListener('conversationDeleted', function () {
	dataLayer.push({
		event: 'trackChat',
		chatAction: 'chat delete conversation',
	});
});

// Listen for positiveFeedback
chatPanel.addEventListener('positiveConversationFeedbackGiven', function () {
	dataLayer.push({
		event: 'trackChatRating',
		chatAction: 'thumbs up',
	});
});

// Listen for negativeFeedback
chatPanel.addEventListener('negativeConversationFeedbackGiven', function () {
	dataLayer.push({
		event: 'trackChatRating',
		chatAction: 'thumbs down',
	});
});

// Listen for the chatPanelClosed event
chatPanel.addEventListener('chatPanelClosed', function () {
	dataLayer.push({
		event: 'trackChat',
		chatAction: autoPoppedUp ? 'chat close (auto-popup)' : 'chat close',
	});

	sessionStorage.setItem('ChatBotOpen', '');
	sessionStorage.removeItem('conversationId');
	autoPoppedUp = false;
});

// Listen for the chatPanelMinimized event
chatPanel.addEventListener('chatPanelMinimized', function () {
	sessionStorage.setItem('ChatBotOpen', '');
	dataLayer.push({
		event: 'trackChat',
		chatAction: autoPoppedUp ? 'chat minimize (auto-popup)' : 'chat minimize',
	});
});

// Albotta auto popup chat window
if (window.innerWidth > 767 && !autoPoppedUp) {
	autoPopUpChatDelay = setTimeout(function () {
		sessionStorage.getItem('ChatBotAuto');
		sessionStorage.setItem('ChatBotAuto', 'used');
		autoPoppedUp = true;
		openChatBot();
	}, 30000);
}

const chatContainer = document.getElementById('chat-container');
if (chatContainer) {
	chatContainer.addEventListener('click', (e) => {
		const a = (e.target as HTMLAnchorElement).closest('a');
		if (a && a.getAttribute('target') === '_blank') {
			console.log('link clicked, close chat bot');
			sessionStorage.setItem('ChatBotOpen', '');
		}
	});
}

export const openWithMessage = (openAlbotta: boolean, albottaSendMessage: boolean, message: string | undefined) => {
	openAlbotta && openChatBot();
	albottaSendMessage && message && chatPanel.sendMessage(message);
};
