<template>
	<header>
		<div
			ref="segmentsEl"
			class="segments"
		>
			<div class="container">
				<div class="segments__list">
					<top-navigation
						:privateLabel="model.privateLabel"
						:privatePageUrl="model?.privatePageUrl.url"
						:businessLabel="model.businessLabel"
						:businessPageUrl="model?.businessPageUrl.url"
						:agricultureLabel="model?.agricultureLabel"
						:agriculturePageUrl="model?.agriculturePageUrl?.url"
					/>
				</div>
			</div>
		</div>
		<div
			ref="headerEl"
			class="header"
			:class="{ 'header--mobile': isMobileHeader, 'header--sticky': isStickyHeader }"
		>
			<div class="container">
				<div class="header__content">
					<!-- Navigation logo -->
					<div class="header__logo">
						<smart-link
							:url="model.logoUrl.url"
							title="Gå til forside"
						>
							<lazy-image
								v-if="model.logoImage?.type && model.logoImage?.type !== 'Svg'"
								:image="model.logoImage"
							></lazy-image>
							<smart-svg
								v-else
								:url="model.logoImage?.url || IconLogo"
								:width="100"
								:height="100"
							/>
						</smart-link>
					</div>
					<!-- Header navigation -->
					<nav
						v-if="model.headerLinks && model.headerLinks.length"
						class="header__nav"
						:class="{ active: isMenuActive }"
					>
						<div class="header__nav-segments">
							<top-navigation
								:privateLabel="model.privateLabel"
								:privatePageUrl="model?.privatePageUrl.url"
								:businessLabel="model.businessLabel"
								:businessPageUrl="model?.businessPageUrl.url"
								:agricultureLabel="model?.agricultureLabel"
								:agriculturePageUrl="model?.agriculturePageUrl?.url"
							/>
						</div>

						<ul
							ref="navigation"
							class="header__nav-main"
						>
							<li
								v-for="(link, index) in model.headerLinks"
								:key="index"
								class="header__nav-main-item"
							>
								<smart-link
									class="header__nav-main-link"
									:url="link.url ? link.url : '#'"
									:title="link ? link.text : ''"
									:target="link ? link.target : '_self'"
									:trackingData="gtmNavigationalTracking(link.text, 'primary navigation', link.url)"
									:useVueRoute="link.useVueRoute"
								>
									{{ link.text }}
								</smart-link>
							</li>
							<li
								v-if="props.model?.claimsUrl?.url && props.model?.claimsLabelText"
								class="header__nav-main-item header__nav-main-item__claims"
							>
								<smart-link
									class="header__nav-main-link btn btn--outline btn--secondary btn--md text-dark"
									:url="model?.claimsUrl?.url"
								>
									{{ props.model?.claimsLabelText }}
								</smart-link>
							</li>
						</ul>

						<teleport
							v-if="!storybook"
							to="#app"
						>
							<transition name="fade">
								<div
									v-if="isMenuActive"
									class="header__nav-backdrop"
									@click="handleNavBackdropClick()"
								></div>
							</transition>
						</teleport>
					</nav>
					<!-- Navigation search -->
					<!-- <NavigationSearch /> -->
					<!-- Navigation actions -->
					<div class="header__actions">
						<!-- Claims -->
						<smart-link
							v-if="props.model?.claimsUrl?.url && props.model?.claimsLabelText"
							class="text-upper"
							:class="
								isMobileHeader
									? 'header__actions--hide'
									: 'btn btn--outline btn--secondary btn--sm text-dark header__actions--claims'
							"
							:url="model?.claimsUrl?.url"
						>
							{{ props.model?.claimsLabelText }}
						</smart-link>
						<!-- Log in link -->
						<smart-link
							v-if="props.model?.loginUrl.url"
							class="text-upper"
							:class="isMobileHeader ? 'btn btn--primary btn--link' : 'btn btn--secondary btn--sm'"
							:url="model?.loginUrl?.url"
						>
							<smart-svg :url="IconSystemLogin"></smart-svg>
							{{ props.model?.loginLabelText }}
						</smart-link>
						<!-- Mobile menu toggle button -->
						<button
							class="header__toggle btn btn--secondary btn--link text-upper"
							@click="appStateStore.toggleMenu(!isMenuActive)"
						>
							<div
								class="btn__hamburger"
								:class="{ 'btn__hamburger--active': isMenuActive }"
							>
								<div class="btn__hamburger-bar"></div>
								<div class="btn__hamburger-bar"></div>
								<div class="btn__hamburger-bar"></div>
							</div>
							{{ menuBtnLabel }}
						</button>
					</div>
				</div>
			</div>
		</div>
	</header>
</template>

<script setup lang="ts">
	import { computed, ref, onMounted, onBeforeUnmount, watch } from 'vue';
	import { useAppStateStore } from '@/stores/appState';
	import { useBreakpointsStore } from '@/stores/breakpoints';
	import { IHeaderBlock } from '@/types/layout';
	import debounce from 'lodash/debounce';
	import IconLogo from '@/assets/svg/AB_logo_XL.svg';
	import IconSystemLogin from '@/assets/svg/icon_system_Lock.svg';
	import router from '@/router';
	import TopNavigation from './TopNavigation/TopNavigation.vue';
	import { gtmNavigationalTracking } from '@/components/utilities/trackingUtility';

	const appStateStore = useAppStateStore();
	const breakpointsStore = useBreakpointsStore();

	// Props
	const props = defineProps<{
		model: IHeaderBlock;
		storybook?: boolean;
	}>();

	// El refs
	const segmentsEl = ref(null);
	const headerEl = ref(null);

	// Data
	const headerHeight = ref(0);
	const segmentsHeight = ref(0);

	// Computed
	// Search functionality??
	// const searchBtnLabel = computed(() => {
	// 	return props.model?.searchLabelText || '';
	// });
	const menuBtnLabel = computed(() => {
		return props.model?.menuMobilLabelText || '';
	});
	const isMobileHeader = computed(() => {
		return breakpointsStore.matches('md');
	});
	const isStickyHeader = computed(() => {
		return appStateStore.scrollPosition > segmentsHeight.value;
	});
	const isMenuActive = computed(() => {
		return appStateStore.isMenuOpen;
	});

	// Methods
	const handleNavBackdropClick = () => {
		appStateStore.toggleMenu(false);
	};
	const setHeaderHeight = () => {
		headerHeight.value = headerEl.value ? (headerEl.value as HTMLElement).offsetHeight : 0;
		segmentsHeight.value = segmentsEl.value ? (segmentsEl.value as HTMLElement).offsetHeight : 0;
		document.documentElement.style.setProperty('--headerHeight', `${headerHeight.value}px`);
		document.documentElement.style.setProperty('--segmentsHeight', `${segmentsHeight.value}px`);
	};
	const handleResize = () => {
		setHeaderHeight();
	};

	// Lifecycle hooks
	window.addEventListener('resize', debounce(handleResize, 100));

	onMounted(() => {
		setTimeout(() => {
			setHeaderHeight();
		}, 100);
	});

	onBeforeUnmount(() => {
		window.removeEventListener('resize', handleResize);
	});

	router.afterEach(() => {
		window.scrollTo(0, 0);
	});

	watch(
		() => isMenuActive.value,
		(isActive) => {
			document.body.style.overflow = isActive ? 'hidden' : 'auto';
		}
	);
</script>

<style lang="scss">
	@import './LayoutNavigation.module.scss';
</style>
