<!-- If parent is NOT container -->
<template>
	<template v-if="!isContained">
		<template
			v-for="(block, b) in model.items"
			:key="b"
		>
			<div
				:class="[
					blockClassPadding(block),
					block.content.contentType,
					hasBg(block),
					isFullscreen(block),
					setBgImageClass(block),
					getBgColorClasses(block),
					staticMarginTop(block),
				]"
				:style="[blockStyleBackground(block), containerBgImg(block)]"
			>
				<div class="container">
					<div class="grid grid--center">
						<div :class="blockClassGrid(block)">
							<!-- <div :data-epi-content-id=" cmsContextStore.isEditable ? block.contentLink.guidValue : null"> -->
							<block-component-selector
								:model="block.content"
								:bgColor="block?.style?.innerBackgroundColor?.colorHex"
								:style="{ '--blockBgColor': setParagraphFadeColor(block) }"
							/>
							<!-- </div> -->
						</div>
					</div>
				</div>
			</div>
		</template>
	</template>

	<!-- If parent IS container -->
	<template v-else>
		<template
			v-for="(block, b) in model.items"
			:key="b"
		>
			<div
				class="sub-block"
				:class="[blockClassGrid(block), spacing(block), getSubBlockBgColorClasses(block)]"
				:style="{ backgroundColor: getBgColor(block), '--blockBgColor': setParagraphFadeColor(block) }"
			>
				<block-component-selector :model="block.content" />
			</div>
		</template>
	</template>
</template>

<script setup lang="ts">
	import { IContentArea, IContentItem } from '@/types/content.js';
	import BlockComponentSelector from '@/components/blocks/BlockComponentSelector/BlockComponentSelector.vue';

	// const cmsContextStore = useCmsContextStore();

	const props = withDefaults(
		defineProps<{
			model: IContentArea;
			isContained?: boolean;
			containerBg?: string;
		}>(),
		{
			isContained: false,
		}
	);

	// Methods
	const blockStyleBackground = (block: IContentItem) => {
		return block?.style?.innerBackgroundColor ? `backgroundColor: ${block?.style?.innerBackgroundColor}` : '';
	};

	const isFullscreen = (block: IContentItem) => {
		if (block.content) {
			if (block.content.fullscreen) {
				return 'fullscreen-block';
			}
		}
		return 'block';
	};

	const hasBg = (block: IContentItem) => {
		if (block?.style?.innerBackgroundColor) {
			if (block.style.innerBackgroundColor.colorHex === '#052951') {
				return 'hasBG text-light';
			}
			return 'hasBG';
		}
		return 'noBG';
	};

	const staticMarginTop = (block: IContentItem) => {
		if (block.content.staticMarginTop) {
			return 'mt-3';
		}
		return '';
	};

	const setBgImageClass = (block: IContentItem) => {
		if (block.content.contentType === 'DefaultContainerBlock' && block.content.image && block.content.image.url) {
			return 'bgImage';
		}
		return '';
	};

	const getBgColor = (block: IContentItem) => {
		if (block?.style?.innerBackgroundColor) {
			return block.style.innerBackgroundColor.colorHex;
		} else if (props.containerBg) {
			return props.containerBg;
		}

		return 'white';
	};

	const setParagraphFadeColor = (block: IContentItem) => {
		if (
			block.content.backgroundColor &&
			block.content.backgroundColor?.colorHex &&
			block.content.backgroundColor?.colorHex !== '0'
		) {
			return block.content.backgroundColor.colorHex.toString();
		} else if (block.style.innerBackgroundColor && block.style.innerBackgroundColor?.colorHex) {
			return block.style.innerBackgroundColor.colorHex;
		} else if (props.containerBg) {
			return props.containerBg;
		}
		console.log(4);
		return 'white';
	};

	const getBgColorClasses = (block: IContentItem) => {
		if (block.style.innerBackgroundColor?.cssClass) {
			return block.style.innerBackgroundColor.cssClass;
		}
		return 'bg-white text-dark';
	};
	const getSubBlockBgColorClasses = (block: IContentItem) => {
		if (block.style.innerBackgroundColor?.cssClass) {
			return block.style.innerBackgroundColor.cssClass;
		}
		return '';
	};

	// Allow focal point to be set on image
	const imageFocusStyle = (block: IContentItem) => {
		if (!block.content.image?.properties?.imageFocalPoint) return '';

		const { imageFocalPoint: fp } = block.content.image.properties;
		const focusX = parseFloat(fp.x) * 100;
		const focusY = parseFloat(fp.y) * 100;

		return `${!isNaN(focusX) ? focusX : 50}% ${!isNaN(focusY) ? focusY : 50}%`;
	};

	// Container with background image
	const containerBgImg = (block: IContentItem) => {
		const { image, imageOverlay } = block?.content ?? {};
		if (block.content.contentType !== 'DefaultContainerBlock' || !image?.url) return '';

		// Add transparancy to backgroundcolor from CMS
		const overlayColor = block?.style?.innerBackgroundColor.colorHex + 'e6';

		// Background image without overlay
		if (!imageOverlay)
			return `backgroundImage: url('${image?.url}'); backgroundPosition: ${imageFocusStyle(block)}`;
		// Background image with overlay
		else if (imageOverlay)
			return `background: linear-gradient(${overlayColor}, ${overlayColor}), url('${
				image?.url
			}'); backgroundPosition: ${imageFocusStyle(block)}`;

		return '';
	};

	const spacing = (block: any) => {
		if (block?.style) {
			const top = block.style.marginTop;
			const bot = block.style.marginBottom;

			let topClass = 'mt-0';
			let botClass = 'mb-0';

			if (top === 'default') {
				topClass = 'mt-0';
			} else {
				topClass = 'mt-' + top;
			}
			if (bot === 'default') {
				botClass = 'mb-0';
			} else {
				botClass = 'mb-' + bot;
			}

			return topClass + ' ' + botClass;
		}
		return 'mt-0 mb-0';
	};

	// const blockStylePadding = (block: IContentItem) => {
	//     let paddingStyle = ''0
	//     if (block.style.marginTop !== 'default') {
	//         paddingStyle += `padding-top: ${parseInt(block.style.marginTop) * 16}px; `
	//     }
	//     if (block.style.marginBottom !== 'default') {
	//         paddingStyle += `padding-bottom: ${parseInt(block.style.marginBottom) * 16}px; `
	//     }
	//     return paddingStyle
	// }

	const blockClassGrid = (block: IContentItem) => {
		let gridClass = '';

		if (block?.grid == null) {
			gridClass += 'span12';
			return gridClass;
		}

		if (block?.grid?.gridSize !== '') {
			gridClass += block.grid.gridSize;
		} else {
			gridClass += 'span12';
		}

		if (block?.grid?.gridMove) {
			gridClass += ` ${block.grid.gridMove}`;
		}

		if (block?.grid.tabletGridSize && block.grid.tabletGridSize !== 'None') {
			gridClass += ` ${block.grid.tabletGridSize}--md`;
		}

		if (block?.grid.tabletGridMove) {
			gridClass += ` ${block.grid.tabletGridMove}--md`;
		}

		if (block?.grid.mobileGridSize && block.grid.mobileGridSize !== 'None') {
			gridClass += ` ${block.grid.mobileGridSize}--sm`;
		}

		if (block?.grid.mobileGridMove) {
			gridClass += ` ${block.grid.mobileGridMove}--sm`;
		}

		return gridClass;
	};

	const blockClassPadding = (block: IContentItem) => {
		let paddingClass = '';

		if (block?.style != null) {
			if (block.style.marginTop !== 'default') {
				paddingClass += `pt-${block.style.marginTop} `;
			}
			if (block.style.marginBottom !== 'default') {
				paddingClass += `pb-${block.style.marginBottom} `;
			}
		}

		return paddingClass;
	};
</script>

<style lang="scss">
	@import './CmsContentArea.module.scss';
</style>
