<!-- eslint-disable vue/no-v-html -->
<template>
	<div class="swipe-deck-container">
		<div
			v-if="model.text"
			class="swipe-deck-container__title text-center"
			v-html="model.text"
		></div>

		<div class="swipe-deck-container__item-container">
			<swiper
				:breakpoints="breakpoints"
				:pagination="{ clickable: true }"
				:modules="modules"
			>
				<swiper-slide
					v-for="(item, i) in model.mainArea.items"
					:key="i"
				>
					<SwipeDeckItemBlock
						:model="item.content"
						:storybook="storybook ? storybook : false"
					/>
				</swiper-slide>
			</swiper>
		</div>
	</div>
</template>

<script setup lang="ts">
	import { ISwipeDeckContainerBlock } from '@/types/block';
	import { SwipeDeckItemBlock } from '../SwipeDeckItemBlock';

	import { Swiper, SwiperSlide } from 'swiper/vue';
	import { Pagination } from 'swiper';

	import 'swiper/scss';
	import 'swiper/scss/pagination';

	const props = defineProps<{
		model: ISwipeDeckContainerBlock;
		storybook?: boolean;
	}>();

	const getSlidesPerView = (max: number) => {
		const itemCount = props.model.mainArea.items.length;
		return itemCount < max ? itemCount : max;
	};

	const modules = [Pagination];

	const breakpoints = {
		1600: {
			slidesPerView: getSlidesPerView(4),
		},
		1320: {
			slidesPerView: getSlidesPerView(4),
		},
		1024: {
			slidesPerView: getSlidesPerView(4),
		},
		768: {
			slidesPerView: getSlidesPerView(3),
		},
		576: {
			slidesPerView: getSlidesPerView(2),
		},
		320: {
			slidesPerView: 1,
		},
		0: {
			slidesPerView: 1,
		},
	};
</script>
<style lang="scss">
	@import './SwipeDeckContainerBlock.module.scss';
</style>
