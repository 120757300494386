<!-- eslint-disable vue/no-v-html -->
<template>
	<div class="spot-highlight">
		<div class="spot-highlight__content">
			<div class="rich-text">
				<h2>{{ model.heading }}</h2>
				<hr class="text-left" />
				<div v-html="model.textArea"></div>
				<p
					v-if="model.cta"
					class="text-center"
				>
					<smart-link
						v-if="model.cta.link.url"
						class="btn"
						:class="getButtonClasses"
						:url="model.cta.link.url"
						:target="model.cta.target"
						:title="model.cta.title"
						:gtm-action="model.cta.gtmAction"
						:gtm-label="model.cta.gtmLabel"
					>
						{{ model.cta.title }}
					</smart-link>
				</p>
			</div>
		</div>
		<div class="spot-highlight__image">
			<lazy-image
				v-if="!storybook"
				:image="model.image"
				:ratio="'1'"
			></lazy-image>

			<!-- For storybook (storybook cannot render lazy images) -->
			<picture
				v-if="model.image.url && storybook"
				class="hero__image"
				st
			>
				<img
					class="lazy-image__full"
					style="opacity: 1"
					alt=""
					:src="model.image.url"
				/>
			</picture>
		</div>
	</div>
</template>

<script setup lang="ts">
	import { computed } from 'vue';
	import { ISpotHighlightBlock } from '@/types/block';

	const props = defineProps<{
		model: ISpotHighlightBlock;
		storybook?: boolean;
	}>();

	const getButtonClasses = computed(() => {
		if (props.model.cta) {
			const size = props.model.cta.btnSize ? props.model.cta.btnSize : '';
			const type = props.model.cta.btnType ? props.model.cta.btnType : '';
			const color = props.model.cta.btnColor ? props.model.cta.btnColor : '';
			return `btn--${size} btn--${type} btn--${color}`;
		} else {
			return '';
		}
	});
</script>

<style lang="scss">
	@import './SpotHighlightBlock.module.scss';
</style>
