import { defineStore } from 'pinia';
import isEmpty from 'lodash/isEmpty';
import { ContentResolver, ResolvedContent, ResolvedContentStatus } from '@/episerver/ContentResolver';
import { ContentData, PageData } from '@/episerver/models';
import { useCmsContextStore } from './cmsContext';

export type FrontendConfig = {
	footerReference?: any;
	headerReference?: any;
	notificationReference?: any;
};

export type CmsDataState = {
	model: ContentData | Record<string, never>;
	configuration: FrontendConfig;
	modelLoaded: boolean;
	status: ResolvedContentStatus;
};

export const useCmsDataStore = defineStore('cms-data', {
	state: (): CmsDataState => ({
		model: {},
		configuration: {},
		modelLoaded: false,
		status: ResolvedContentStatus.Unknown,
	}),
	actions: {
		setModel(payload: ResolvedContent<PageData>) {
			this.model = payload.content || {};

			if (!isEmpty(payload.content?.frontendConfiguration)) {
				this.configuration = (payload.content?.frontendConfiguration as FrontendConfig) || {};
			}

			this.modelLoaded = payload.status === ResolvedContentStatus.Resolved;
			this.status = payload.status;
		},
		async updateModelByUrl(url: string) {
			const contentResolver = new ContentResolver();

			const cmsContextStore = useCmsContextStore();

			return contentResolver
				.resolveContent(url, true)
				.then((resolvedContent) => {
					this.setModel({ ...resolvedContent } as ResolvedContent<PageData>);
					const context = {
						isEditable: resolvedContent.mode === 'EDIT',
						inEditMode: resolvedContent.mode === 'EDIT',
					};

					cmsContextStore.setContext(context);
				})
				.catch(() => {
					this.setModel({ status: ResolvedContentStatus.Unknown } as ResolvedContent<PageData>);
				});
		},
	},
});
