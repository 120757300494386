import { defineStore } from 'pinia';

export const useAppStateStore = defineStore('app-state', {
	state: () => ({
		scrollPosition: 0,
		isMenuOpen: false,
	}),
	actions: {
		setScrollPosition(value: number) {
			this.scrollPosition = value;
		},
		toggleMenu(payload: boolean) {
			this.isMenuOpen = payload;
		},
	},
});
