<template>
	<div class="trustpilot">
		<div
			class="trustpilot-widget"
			:data-locale="model.reviewLanguages"
			:data-template-id="model.templateId"
			:data-businessunit-id="model.businessUnitId"
			:data-style-height="model.styleHeight"
			:data-style-width="model.styleWidth"
			:data-theme="model.theme"
			:data-stars="model.stars"
		>
			<a
				:href="model.url"
				target="_blank"
				rel="noopener"
				>Trustpilot</a
			>
		</div>
	</div>
</template>

<script setup lang="ts">
	import { onMounted } from 'vue';
	import { ITrustpilotBlock } from '@/types/block';

	defineProps<{
		model: ITrustpilotBlock;
	}>();

	onMounted(() => {
		const trustpilotScript = document.createElement('script');
		trustpilotScript.setAttribute('type', 'text/javascript');
		trustpilotScript.setAttribute('src', '//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js');
		document.head.appendChild(trustpilotScript);
	});
</script>
