<template>
	<div class="video">
		<template v-if="model.thumbnailImage?.url">
			<div
				class="video__preview"
				:class="{ 'video__preview--hide': videoPlaying }"
			>
				<lazy-image
					:image="model.thumbnailImage"
					:ratio="playerRatio"
				/>
			</div>
			<button
				id="play-button"
				class="video__play-button btn btn--xxl btn--cta-animated btn--secondary"
				:class="{
					'btn--sm-pad': !model.durationOverride,
					'video__play-button--hide': videoPlaying && !videoEnded,
				}"
				type="button"
				@click="playVideo"
			>
				<smart-svg :url="IconSystemPlay" />
				{{ model.durationOverride }}
			</button>
			<iframe
				:id="`player-${model.youtubeId}`"
				class="video__embedded"
				:width="playerWidth"
				:height="playerHeight"
				:src="videoSource"
				:style="playerRatioStyle"
				frameborder="0"
				allow="encrypted-media; fullscreen"
			/>
		</template>
	</div>
</template>

<script setup lang="ts">
	import { computed, onBeforeUnmount, onMounted, ref } from 'vue';
	import { IYoutubeBlock } from '@/types/block';
	import IconSystemPlay from '@/assets/svg/icon_system_Play.svg';
	import { dataLayer } from '@/components/utilities/trackingUtility';

	const props = defineProps<{
		model: IYoutubeBlock;
	}>();

	const videoPlaying = ref(false);
	const videoEnded = ref(false);
	const videoDuration = ref('00:00');
	const scrollPosition = ref(0);

	let player: any;

	const playerWidth = computed(() => {
		return props.model.playerWidth ?? 1600;
	});

	const playerHeight = computed(() => {
		return props.model.playerHeight ?? 900;
	});

	const playerRatio = computed(() => {
		return `${playerWidth.value}/${playerHeight.value}`;
	});

	const playerRatioStyle = computed(() => {
		return `aspect-ratio: ${playerRatio.value}`;
	});

	const videoSource = computed(() => {
		return `https://www.youtube-nocookie.com/embed/${props.model.youtubeId}?rel=0&enablejsapi=1&mute=1&origin=${window.location.origin}`;
	});

	const handleFullScreenChange = () => {
		if (!document.fullscreenElement) {
			window.scrollTo(0, scrollPosition.value);
		}
	};

	onMounted(() => {
		const tag = document.createElement('script');
		tag.src = 'https://www.youtube.com/iframe_api';
		const firstScriptTag = document.getElementsByTagName('script')[0];
		firstScriptTag.parentNode?.insertBefore(tag, firstScriptTag);

		if ((window as any).YT?.Player) {
			createPlayer();
		} else {
			// If the array doesn't exist yet, create it
			if (!(window as any).onYouTubeIframeAPIReadyCallbacks) {
				(window as any).onYouTubeIframeAPIReadyCallbacks = [];
			}

			// Add createPlayer to the array of callbacks
			(window as any).onYouTubeIframeAPIReadyCallbacks.push(createPlayer);

			// Set onYouTubeIframeAPIReady to a function that calls all the callbacks
			(window as any).onYouTubeIframeAPIReady = () => {
				for (const callback of (window as any).onYouTubeIframeAPIReadyCallbacks) {
					callback();
				}
			};
		}
		const iframe = document.getElementById(`player-${props.model.youtubeId}`) as HTMLElement;
		iframe.addEventListener('fullscreenchange', handleFullScreenChange);
	});

	onBeforeUnmount(() => {
		const iframe = document.getElementById(`player-${props.model.youtubeId}`) as HTMLElement;
		iframe.removeEventListener('fullscreenchange', handleFullScreenChange);
	});

	const createPlayer = () => {
		player = new (window as any).YT.Player(`player-${props.model.youtubeId}`, {
			playerVars: {
				playsinline: 1,
			},
			events: {
				onStateChange: onStateChange,
			},
		});
	};

	const playVideo = () => {
		player.playVideo();
		videoPlaying.value = true;
		scrollPosition.value = window.scrollY;

		const getVideoDuration = player.getDuration();
		const minutes = Math.floor(getVideoDuration / 60);
		const seconds = Math.floor(getVideoDuration - minutes * 60);
		videoDuration.value = `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;

		dataLayer.push({
			event: 'trackVideoStart',
			videoProvider: 'Youtube',
			videoDuration: videoDuration.value,
			videoUrl: videoSource.value,
		});
	};

	const onStateChange = (event: any) => {
		if (event.data === (window as any).YT.PlayerState.ENDED) {
			videoEnded.value = true;

			dataLayer.push({
				event: 'trackVideoComplete',
				videoProvider: 'Youtube',
				videoDuration: videoDuration.value,
				videoUrl: videoSource.value,
			});
		}

		if (event.data === (window as any).YT.PlayerState.PLAYING) {
			videoPlaying.value = true;
			videoEnded.value = false;
		}
	};
</script>

<style lang="scss">
	@import './YoutubeBlock.module.scss';
</style>
