<template>
	<div class="swipe-deck-item p-3 py-0">
		<smart-link
			class="swipe-deck-item__content p-1"
			:url="getUrl()"
			:target="model.target"
			:style="model.link?.url ? 'pointer-events:auto' : 'pointer-events:none'"
			:gtmLabel="model?.gtmLabel ? model?.gtmLabel : ''"
			:gtmAction="model?.gtmAction ? model?.gtmAction : ''"
			:useVueRoute="model.link?.useVueRoute"
		>
			<lazy-image
				v-if="model.icon && model.icon.url && !storybook"
				class="swipe-deck-item__image"
				:image="model.icon"
				:ratio="'none'"
			/>

			<!-- For storybook (storybook cannot render lazy images) -->
			<picture
				v-if="model.icon && model.icon.url && storybook"
				class="swipe-deck-item__image"
				:style="{ 'background-image': `url(${model.icon.url})` }"
			>
				<img
					class="lazy-image__full"
					style="opacity: 1; width: 56px; margin: auto; margin-bottom: 20px"
					alt=""
					:src="model.icon.url"
				/>
			</picture>

			<p class="swipe-deck-item__title text-center">{{ model.title }}</p>
		</smart-link>
	</div>
</template>

<script setup lang="ts">
	import { ISwipeDeckItemBlock } from '@/types/block';

	const props = defineProps<{
		model: ISwipeDeckItemBlock;
		storybook: boolean;
	}>();

	const getUrl = () => {
		return props.model.link && props.model.link?.url ? props.model.link.url : '#';
	};
</script>
<style lang="scss" scoped>
	@import './SwipeDeckItemBlock.module.scss';
</style>
