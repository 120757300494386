<template>
	<picture
		v-if="image.url"
		ref="wrapEl"
		class="lazy-image"
		:class="{ 'lazy-image--loaded': isLoaded }"
		:style="[
			imgStyle === 'square' ? 'width:125px;margin:auto;' : imgStyle,
			`background-position: ${imageFocusStyle}`,
		]"
	>
		<img
			ref="imageEl"
			v-inview="{ offset: `${props.preloadOffset}px` }"
			class="lazy-image__full"
			:style="[imgStyle === 'square' ? '' : imgStyle, `object-position: ${imageFocusStyle}`]"
			:alt="alt || image.properties?.altText || image.properties?.title || ''"
			@intersecting="enteredView"
		/>
	</picture>
</template>

<script setup lang="ts">
	import { computed, ref } from 'vue';
	import { IImage } from '@/types/block';

	type ImageModel = {
		image: IImage;
		alt?: string;
		ratio?: string;
		preloadOffset?: number;
		fixedHeight?: string | number | boolean;
	};

	// Props
	const props = withDefaults(defineProps<ImageModel>(), {
		ratio: '16/9',
		preloadOffset: 200,
	});

	// Data
	const isLoaded = ref(false);

	// El refs
	const wrapEl = ref<HTMLElement>();
	const imageEl = ref<HTMLImageElement>();

	// Computed
	// TODO: Should be rewritten in a simpler case to tackle all img settings

	const imgStyle = computed(() => {
		if (fixedHeight.value) {
			if (fixedHeight.value === 'square') {
				return 'width:125px;margin:auto;';
			} else if (fixedHeight.value === 'smallSquare') {
				return 'width:56px;margin:auto;';
			}
			return fixedHeight.value;
		}
		return imageRatioStyle.value;
	});

	const imageRatioStyle = computed(() => {
		if (props.ratio === 'default') {
			return 'aspect-ratio: 16/9';
		}
		return `aspect-ratio: ${props.ratio}`;
	});

	const fixedHeight = computed(() => {
		if (props.fixedHeight) {
			if (typeof props.fixedHeight === 'number') {
				return 'height:' + props.fixedHeight + 'px;';
			}
			if (props.fixedHeight === 'usp') {
				return 'square';
			} else if (props.fixedHeight === 'uspSmall') {
				return 'smallSquare';
			}
		}
		return '';
	});

	const imageFocusStyle = computed(() => {
		if (!props.image.properties?.imageFocalPoint) {
			return '';
		}

		const { imageFocalPoint: fp } = props.image.properties;
		const focusX = parseFloat(fp.x) * 100;
		const focusY = parseFloat(fp.y) * 100;

		return `${!isNaN(focusX) ? focusX : 50}% ${!isNaN(focusY) ? focusY : 50}%`;
	});

	const thumbImgUrl = computed((): string => {
		const widthParam = `width=${256}`;
		// const thumbHeight = 256 / (props.width / props.height);
		// const thumbHeight = 256
		const qualityParam = `quality=${30}`;
		// const { imageFocalPoint: fpData } = props.image.properties
		// const fpParam = fpData ? `rxy=${fpData.x},${fpData.y}` : ''
		return `${props.image.url}?${widthParam}&${qualityParam}`;
	});

	const responsiveImgUrl = computed((): string => {
		const imgElWidth = imageEl.value ? imageEl.value.offsetWidth : 0;
		const imgRequiredWidth = imgElWidth * window.devicePixelRatio;
		// const imgHeight = imageEl.value ? imageEl.value.offsetHeight : 0;

		if (!imgRequiredWidth) {
			return props.image.url;
		}

		const imgPresets = [500, 1024, 1280, 1600, 1920];
		const imgLargerPresets = imgPresets.filter((num) => num >= imgRequiredWidth);
		const closestPreset = imgLargerPresets.length ? imgLargerPresets[0] : imgPresets[imgPresets.length - 1];
		const imgWidthParam = `width=${closestPreset}`;

		const imgQualityParam = `quality=${92}`;

		// const { imageFocalPoint: fpData } = props.image.properties
		// const fpParam = fpData ? `rxy=${fpData.x},${fpData.y}` : ''

		return `${props.image.url}?${imgWidthParam}&${imgQualityParam}`;
	});

	// Methods

	const enteredView = () => {
		loadImage(thumbImgUrl.value)
			.then(() => {
				wrapEl.value!.style.backgroundImage = `url(${thumbImgUrl.value})`;
				return loadImage(responsiveImgUrl.value);
			})
			.then(() => {
				imageEl.value!.src = responsiveImgUrl.value;
				isLoaded.value = true;
			})
			.catch((e) => {
				console.log('image load error', e);
			});
	};

	const loadImage = (imgUrl: string): Promise<void> => {
		return new Promise((resolve, reject) => {
			const image = new Image();
			image.onload = () => {
				resolve();
			};
			image.onerror = () => {
				reject();
			};
			image.src = imgUrl;
		});
	};
</script>

<style lang="scss">
	@import './LazyImage.module.scss';
</style>
