<template>
	<div
		ref="smartImgEl"
		v-inview="{ offset: `${props.preloadOffset}px` }"
		class="smart-img"
		:class="{ 'smart-img--bg': asBgImg, 'smart-img--placeholder': imgError }"
		:style="aspectRatioStyle"
		@intersecting="enteredView"
	>
		<div
			ref="thumbImgEl"
			class="smart-img__thumb"
			:class="thumbImageClass"
			:style="focalPointPosition"
			:data-src="thumbImgUrl"
		></div>
		<div
			v-if="hasImgSize"
			ref="fullImgEl"
			class="smart-img__full"
			:class="fullImageClass"
			:style="focalPointPosition"
			:data-src="responsiveImgUrl"
		></div>
	</div>
</template>

<script setup lang="ts">
	/* eslint-disable */
	import { onMounted, onBeforeMount, ref, reactive, computed, nextTick } from 'vue';
	import { isEmpty } from 'lodash-es';
	import { FocalPoint } from '@/types';

	interface Props {
		url: string;
		alt?: string;
		width: number;
		height: number;
		focalPoint?: FocalPoint;
		ratio?: string;
		preloadOffset?: number;
		type?: 'inline' | 'bg';
	}
	const props = withDefaults(defineProps<Props>(), {
		preloadOffset: 250, // trigger preload 250px before enter viewport
		type: 'inline',
	});

	const smartImgEl = ref<HTMLElement>();
	const thumbImgEl = ref<HTMLElement>();
	const fullImgEl = ref<HTMLElement>();

	let imgHeight = ref(0);
	let imgWidth = ref(0);
	const ratioPadding = Math.round((props.height / props.width) * 100 * 100) / 100;
	const thumbImg = reactive({
		width: 256,
		height: 256 / (props.width / props.height),
		quality: 30,
	});
	let imgLoaded = ref(false);
	let imgError = ref(false);
	const imgSizes = [500, 1024, 1280, 1600, 1920];
	const imgQuality = 92;
	let hasImgSize = ref(false);
	let listeners = reactive(new Map()); // map eventlisteners functions for unmounting
	let pixelRatio = ref(0);

	const thumbImgUrl = computed((): string => {
		return `${props.url}?width=${thumbImg.width}&quality=${thumbImg.quality}`;
	});
	const responsiveImgUrl = computed((): string => {
		return imgWidth ? `${props.url}?width=${closest.value}&quality=${imgQuality}` : props.url;
	});
	const closest = computed((): number | null => {
		const closestPreset = imgSizes.filter((num) => num >= imgWidth.value!);
		let imgSize = closestPreset.length ? closestPreset[0] : imgSizes[imgSizes.length - 1];

		// get one imgsize bigger if available and screen is retina
		if (isRetina && closestPreset.length >= 2) imgSize = closestPreset[1];

		return imgWidth ? imgSize : null;
	});
	const hasFocalPoint = computed((): boolean => {
		return !isEmpty(props.focalPoint);
	});
	const focalPointPosition = computed((): string => {
		return hasFocalPoint.value
			? `background-position: ${+props.focalPoint!.x! * 100}% ${+props.focalPoint!.y! * 100}%`
			: '';
	});
	const aspectRatioStyle = computed((): string => {
		if (props.type === 'inline') {
			if (props.ratio) {
				const splitFration = props.ratio?.split('/');
				const parsedFraction = +splitFration[0] / +splitFration[1];
				return `padding-bottom: ${(1 / parsedFraction) * 100}%`;
			}
			return `padding-bottom: ${ratioPadding}%`;
		}
		return '';
	});
	const asBgImg = computed((): boolean => {
		return props.type === 'bg';
	});
	const isRetina = computed((): boolean => {
		return pixelRatio.value >= 2;
	});
	const bgSize = computed((): string => {
		return props.type === 'inline' && !props.ratio ? 'contain' : 'cover';
	});
	const thumbImageClass = computed(() => {
		return [`smart-img__thumb--${bgSize.value}`, { 'smart-img__thumb--rem0ve': imgLoaded.value }];
	});
	const fullImageClass = computed(() => {
		return `smart-img__full--${bgSize.value}`;
	});

	const removeThumbnail = () => {
		setTimeout(() => {
			const thumbEl = thumbImgEl.value;
			if (!thumbEl) return;
			thumbEl.remove();
		}, 600);
	};
	const enteredView = () => {
		loadImage(thumbImgEl.value as HTMLElement)
			.then(() => {
				hasImgSize.value = true;
				imgLoaded.value = false;

				nextTick(() => {
					loadImage(fullImgEl.value as HTMLElement)
						.then(() => {
							imgLoaded.value = true;

							removeThumbnail();
						})
						.catch(() => {
							imgError.value = true;
						});
				});
			})
			.catch(() => {
				imgError.value = true;
			});
	};
	const loadImage = (elem: HTMLElement) => {
		return new Promise((resolve, reject) => {
			const image = new Image();
			const imageSrc = elem.dataset.src!;

			if (!imageSrc) return;

			image.addEventListener('load', resolve);
			image.addEventListener('error', reject);
			image.src = imageSrc;
			listeners.set(image, resolve).set(image, reject);

			elem.style.backgroundImage = `url(${imageSrc})`;
			delete elem.dataset.src;
		});
	};

	onBeforeMount(() => {
		pixelRatio.value = window.devicePixelRatio;
		listeners.forEach((value, key) => key.removeEventListener('load', value));
	});
	onMounted(() => {
		// TODO add animation option
		// TODO add critical option, no thumb img
		// TODO add maybe full option?
		// TODO get biggest size vmax and dertermine size
		// TODO resize event to get new size
		imgWidth.value = smartImgEl.value!.offsetWidth;
		imgHeight.value = smartImgEl.value!.offsetHeight;
	});
</script>

<style lang="scss">
	@import './SmartImage.module.scss';
</style>
