import { defineStore } from 'pinia';

type CmsContextState = {
	inEditMode: boolean;
	isEditable: boolean;
};

export const useCmsContextStore = defineStore('cms-context', {
	state: (): CmsContextState => ({
		inEditMode: false,
		isEditable: false,
	}),
	actions: {
		setContext(payload: CmsContextState) {
			this.$patch(payload);
		},
	},
});
