<!-- eslint-disable vue/no-v-html -->
<template>
	<div class="container">
		<div class="grid">
			<div class="span8 move2">
				<!-- <pre>Søgeresultat (Product Page)</pre> -->
				<h1>Søgeresultat for: {{ searchStore.query }}</h1>
				<div
					v-if="searchStore.results.length"
					ref="searchResultsEl"
					class="search__results"
				>
					<p>
						Søgeresultater: <strong>{{ searchStore.resultsTotal }}</strong>
					</p>
					<ul>
						<li
							v-for="(result, index) in searchStore.results"
							:key="index"
						>
							<smart-link
								class="search__results-item"
								:url="result.url"
							>
								<h2 v-html="result.title" />
								<div v-html="result.text"></div>
							</smart-link>
						</li>
					</ul>
				</div>
				<div class="search__pagination">
					<pagination
						:total="searchStore.resultsTotal"
						:size="searchStore.resultsPerPage"
						:current="1"
					/>
				</div>
			</div>
		</div>
	</div>
</template>

<script setup lang="ts">
	import { computed, ref, watch } from 'vue';
	import { useSearchStore } from '@/stores/search';
	import { useRouter, useRoute } from 'vue-router';
	// import { ContentData } from '@/episerver/models'
	import { Pagination } from '@/components/shared/index';

	const searchStore = useSearchStore();
	// eslint-disable-next-line no-unused-vars
	const router = useRouter();
	const route = useRoute();

	// const props = defineProps<{
	//     model: ContentData
	// }>()

	const searchResultsEl = ref(null);

	// eslint-disable-next-line no-unused-vars
	const searchResultY = computed(() => {
		if (!searchResultsEl.value) {
			return 0;
		}
		return (searchResultsEl.value as HTMLElement).offsetTop - 170; //offset header height
	});

	watch(
		() => route.query,
		async (query) => {
			const searchQuery = Array.isArray(query.q) ? query.q[0] : query.q;
			const searchPage = Array.isArray(query.p) ? query.p[0] : query.p;
			const searchPageInt = searchPage !== null ? parseInt(searchPage) : 0;
			const searchParams = {
				q: searchQuery || '',
				p: !isNaN(searchPageInt) ? searchPageInt : 0,
			};
			searchStore.getSearchResults(searchParams);
		},
		{
			immediate: true,
		}
	);
</script>

<style lang="scss">
	@import './SearchBlock.module.scss';
</style>
