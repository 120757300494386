<!-- eslint-disable vue/valid-template-root -->
<template></template>

<script setup lang="ts">
	import { computed, watch } from 'vue';
	import { useCmsDataStore } from '@/stores/cmsData';

	const cmsDataStore = useCmsDataStore();

	const meta = computed(() => {
		const { metaDescription, metaKeywords, metaTitle, ogImage, canonicalUrl } = cmsDataStore.model as any;
		return {
			metaDescription,
			metaKeywords,
			metaTitle,
			ogImage,
			canonicalUrl,
		};
	});

	const createMetaTag = (name: string): HTMLMetaElement => {
		const el = document.createElement('meta');
		el.setAttribute('name', name);
		document.head.appendChild(el);
		return el;
	};

	const setCanonicalUrlTest = () => {
		return meta.value.canonicalUrl;
	};

	const setMetaData = () => {
		document.title = meta.value.metaTitle || 'Alm. Brand';

		const metaData = [
			{ type: 'description', value: meta.value.metaDescription || '' },
			{ type: 'keywords', value: meta.value.metaKeywords || '' },
			{ type: 'image', value: meta.value.ogImage && meta.value.ogImage.url ? meta.value.ogImage.url : '' },
		];

		// eslint-disable-next-line no-shadow
		metaData.forEach((meta) => {
			const metaTag = document.querySelector(`meta[name="${meta.type}"]`) || createMetaTag(meta.type);
			metaTag.setAttribute('content', meta.value);
		});
	};

	watch(meta, () => {
		setMetaData();
		setCanonicalUrlTest();
	});
</script>
