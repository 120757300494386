<template>
	<component
		:is="getComponentForModel()"
		:id="readlineId"
		:model="model"
		:bgColor="bgColor"
		:containerBg="containerBg"
	/>
</template>

<script setup lang="ts">
	import { resolveComponent, computed } from 'vue';
	// import { ContentData } from '@/episerver/models'
	import { defaultCmsConfig } from '@/config';
	import { IBlockWithReadline } from '@/types/block';

	const props = defineProps<{
		model: IBlockWithReadline;
		bgColor?: string;
		containerBg?: string;
	}>();

	const getComponentForModel = (): string => {
		// Pick the most specific view, i.e. first view that matches
		// the the content type name in the content type inheritance chain.
		const resolved = resolveComponent(props.model.contentType);
		if (typeof resolved === 'object') {
			return props.model.contentType;
		}
		return defaultCmsConfig.componentSelector.fallbackBlockComponent;
	};

	const readlineId = computed(() => {
		// return props.model.useReadline && props.model.readlineId ? props.model.readlineId : null;
		return props.model.readlineId || null;
	});
</script>
