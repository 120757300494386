<!-- eslint-disable vue/no-v-html -->
<template>
	<div
		class="hero"
		:class="model.heroType === 'fullBottom' ? 'hero--bottom' : 'hero--contain'"
	>
		<lazy-image
			v-if="model.image.url && !storybook"
			class="hero__image"
			:image="model.image"
			:ratio="imageAspectRatio"
		/>

		<!-- For storybook (storybook cannot render lazy images) -->
		<picture
			v-if="model.image.url && storybook"
			class="hero__image"
			style="aspect-ratio: unset; background-size: cover"
			:style="{ 'background-image': `url(${model.image.url})` }"
		>
			<img
				class="lazy-image__full"
				:style="[{ aspectRatio: model.heroType === 'fullBottom' ? '3/1' : 'unset' }]"
				alt=""
				:src="model.image.url"
			/>
		</picture>

		<div class="hero__container">
			<div
				class="grid"
				:class="contentAlignment"
			>
				<div :class="contentGridClass">
					<div
						ref="contentContainer"
						class="hero__content"
						:data-font-ratio="getDynamicHeadingFontRatio"
						:class="[getContentBg, `px-${getContentPaddingX()}`, `py-${getContentPaddingY()}`]"
					>
						<countdown
							v-if="model.countdownEndDate && model.countdownPositionTop"
							:title="model.countdownTitle"
							:endDate="model.countdownEndDate"
							:expiredText="model.countdownExpiredText"
							:positionTop="model.countdownPositionTop"
						/>
						<div class="rich-text text-center">
							<p
								v-if="model.tagline"
								class="tagline"
							>
								{{ model.tagline }}
							</p>
							<h1 v-if="model.title">{{ model.title }}</h1>
							<hr
								v-if="model.tagline && model.title"
								class="hero__line"
							/>
							<div
								v-if="model.textArea"
								class="hero__text-content"
								v-html="model.textArea"
							></div>
							<countdown
								v-if="model.countdownEndDate && !model.countdownPositionTop"
								:title="model.countdownTitle"
								:endDate="model.countdownEndDate"
								:expiredText="model.countdownExpiredText"
							/>
							<p
								v-if="model.ctaBlock"
								class="text-center"
							>
								<smart-link
									class="btn"
									:class="getButtonClasses"
									:url="getUrl"
									:target="model.ctaBlock.target"
									:title="model.ctaBlock.title"
									:gtm-action="model.ctaBlock.gtmAction"
									:gtm-label="model.ctaBlock.gtmLabel"
									:useVueRoute="model.ctaBlock.link.useVueRoute"
									:trackingData="
										gtmNavigationalTracking(props.model.ctaBlock.title, 'call-to-action', getUrl)
									"
									:promotionalTracking="
										gtmTrackSelectPromotion(
											model.image?.properties?.title || 'missing image title',
											model.contentType,
											handleGtmLabel(model.textArea, model.ctaBlock?.gtmLabel)
										)
									"
								>
									{{ model.ctaBlock.title }}
								</smart-link>
							</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script setup lang="ts">
	import { ref, onMounted, onUpdated, computed } from 'vue';
	import { IHeroBlock } from '@/types/block';
	import { useBreakpointsStore } from '@/stores/breakpoints';
	import {
		dataLayer,
		gtmNavigationalTracking,
		gtmTrackViewPromotion,
		gtmTrackSelectPromotion,
		handleGtmLabel,
	} from '@/components/utilities/trackingUtility';

	const props = defineProps<{
		model: IHeroBlock;
		storybook?: boolean;
	}>();

	const breakpointsStore = useBreakpointsStore();

	const contentContainer = ref<HTMLElement>();

	const getUrl = computed(() => {
		if (props.model.ctaBlock?.link?.url) {
			return props.model.ctaBlock.link.url;
		}
		return '';
	});

	// Calculates fontsize based on screenwidth and font ratio from CMS
	function initDynamicHeading(heading: HTMLElement, container: HTMLElement) {
		if (heading) {
			heading.style.removeProperty('font-size'); // reset if fontsize previously set

			if (container) {
				let maxRatio = 0.11;
				const containerWidth = parseInt(
					window.getComputedStyle(container).getPropertyValue('width').replace('px', ''),
					10
				);

				if (typeof container.dataset.fontRatio == 'string') {
					maxRatio = container.dataset.fontRatio !== '' ? parseFloat(container.dataset.fontRatio) : 0.11; // .11 ratio fallback
				}

				const fontSize = parseInt(
					window.getComputedStyle(heading).getPropertyValue('font-size').replace('px', ''),
					10
				);
				const ratio = fontSize / containerWidth;

				if (ratio > maxRatio) {
					const adaptedFontSize = containerWidth * maxRatio;
					heading.style.fontSize = `${adaptedFontSize}px`;
				}
			}
		}
	}

	// Ensures font ratio from CMS is kept when moving between page segments
	const updateDynamicTitle = () => {
		if (props.model.dynamicTitle) {
			if (contentContainer.value) {
				const headings = contentContainer.value.querySelectorAll(
					'.hero__content h1, .hero__content h2, .hero__content h3, .hero__content h4'
				);
				const windowWidthAtInit = window.innerWidth;

				headings.forEach((heading) => {
					initDynamicHeading(heading as HTMLElement, contentContainer.value as HTMLElement);

					window.addEventListener('resize', () => {
						if (windowWidthAtInit !== window.innerWidth) {
							initDynamicHeading(heading as HTMLElement, contentContainer.value as HTMLElement);
						}
					});
				});
			}
		}
	};

	const handleViewPromotionTracking = () => {
		const { image, contentType, textArea, ctaBlock } = props.model;

		if (!dataLayer || !textArea) return;

		const title = image?.properties?.title || 'missing image title';
		const gtmLabel = handleGtmLabel(textArea, ctaBlock?.gtmLabel);
		gtmTrackViewPromotion(title, contentType, gtmLabel);
	};

	onMounted(() => {
		updateDynamicTitle();
		setTimeout(() => {
			handleViewPromotionTracking();
		}, 1250);
	});

	// Update font ratio on pageload and update (when moving between page segments)
	onUpdated(() => {
		updateDynamicTitle();
	});

	// Returns correct font ratio based on CMS input (scales font size based on screenwidth)
	const getDynamicHeadingFontRatio = computed(() => {
		const { fontRatio } = props.model;
		if (fontRatio) {
			if (fontRatio > 9) {
				return `0.${props.model.fontRatio}`;
			} else {
				return `0.0${props.model.fontRatio}`;
			}
		}
		return 0.11;
	});

	// Padding top and bottom
	const getContentPaddingY = () => {
		return props.model?.paddingTopBottom === 'default' ? '3' : props.model?.paddingTopBottom;
	};

	// Padding sides
	const getContentPaddingX = () => {
		return props.model?.paddingSides === 'default' ? '3' : props.model?.paddingSides;
	};

	// Get content background color based on classes set in the CMS
	const getContentBg = computed(() => {
		const classes = props.model.backgroundColor?.cssClass;

		if (props.model.transparentBackgroundColor) {
			if (!classes) {
				return 'bg-navy-transparent text-light';
			}
			const classesArr = classes?.split(' ');

			const bgClass = classesArr.find((item) => item.includes('bg-'));
			const newBgClass = bgClass + '-transparent';

			const bgClassIndex = classesArr.findIndex((item) => item.includes('bg-'));
			classesArr.splice(bgClassIndex, 1);

			classesArr.push(newBgClass);

			return classesArr.join(' ');
		}
		return classes;
	});

	// Get button classes based on the CMS values for size, type and color
	const getButtonClasses = computed(() => {
		if (props.model.ctaBlock) {
			const { btnSize = '', btnType = '', btnColor = '' } = props.model.ctaBlock;
			return `btn--${btnSize} btn--${btnType} btn--${btnColor}`;
		}
		return '';
	});

	// Align code based on herotype in CMS
	const contentAlignment = computed(() => {
		if (props.model.heroType === 'thirdRight') {
			return 'alignRight';
		}
		return 'alignLeft';
	});

	// Add grid classes to content box based on hero type
	const contentGridClass = computed(() => {
		if (props.model.heroType === 'halfLeft') {
			return 'span6 span12--md';
		}
		if (props.model.heroType === 'thirdLeft') {
			return 'span4 span12--md';
		}
		if (props.model.heroType === 'thirdRight') {
			return 'span4 span12--md';
		}
		return 'span12';
	});

	// Decide image size/ratio
	const imageAspectRatio = computed(() => {
		return props.model.heroType === 'fullBottom' ? (breakpointsStore.matches('sm') ? '4/3' : '3/1') : 'unset';
	});
</script>

<style lang="scss">
	@import './HeroBlock.module.scss';
</style>
